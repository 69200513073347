import { UrlCorreos } from '../variables/variablesConection'


export async function sendCorreo(body) {
    return new Promise((resolve) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "Body": body.Body,
            "Correo": body.Correo,
            "Asunto":body.Asunto
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(UrlCorreos, requestOptions)
            .then(response => response.text())
            .then(result => {
                resolve(result);
            })
            .catch(error => console.log('error', error));
    });
}