export async function getZona(id){
    return new Promise((resolve) => {
        var datos = [
            {nombre:'Centro', zona:{telefono:"+57 3203703495"}},
            {nombre:'Santander', zona:{telefono:"+57 3182754576"}},
            {nombre:'Antioquia', zona:{telefono:"+57 3213782946"}},
            {nombre:'Eje Cafetero', zona:{telefono:"+57 3203698673"}},
            {nombre:'Norte de Santander', zona:{telefono:"+57 3144417876"}},
            {nombre:'Costa', zona:{telefono:"+57 3212228848"}},
            {nombre:'Occidente', zona:{telefono:"+57 3175860335"}},
            {nombre:'Exterior', zona:{telefono:"+57 3213782946"}},
        ]
        var asesor = false;
        var url = id
        datos.forEach(element => {
            if(element.nombre == id){
                asesor = true;
                url = element.zona
            }
        });

        if(asesor){
            resolve(url)
        }else{
            resolve(false)
        }
    })
}