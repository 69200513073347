import * as React from 'react';
import Card from '@mui/material/Card';
import '../cssComponentes/video.css';
import CardContent from '@mui/material/CardContent';

export default function InformacionVideo({ valueShopi }) {
  return (
    <Card sx={{ width: '100%' }}>
      {valueShopi &&
        valueShopi.data &&
        valueShopi.data.products &&
        valueShopi.data.products.edges &&
        valueShopi.data.products.edges.length > 0 &&
        valueShopi.data.products.edges[0].node &&
        valueShopi.data.products.edges[0].node.media &&
        valueShopi.data.products.edges[0].node.media.edges &&
        valueShopi.data.products.edges[0].node.media.edges[1] &&
        valueShopi.data.products.edges[0].node.media.edges[1].node &&
        valueShopi.data.products.edges[0].node.media.edges[1].node.embeddedUrl && (
          <CardContent>
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
              <iframe
                title="Video"
                id="video"
                src={valueShopi.data.products.edges[0].node.media.edges[1].node.embeddedUrl.replace(
                  'youtu.be',
                  'www.youtube.com/embed'
                )}
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </div>
          </CardContent>
        )}
    </Card>
  );
}
