import logo from './logo.svg';
import './App.css';
import Inicio from './componentes/inicio'

function App() {
  return (
    <div className="App" style={{backgroundColor:"black"}}>
        <Inicio></Inicio>
    </div>
  );
}

export default App;
