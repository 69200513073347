
import {GetApp} from '../consumos/sap'
import {urlSAPMY} from '../variables/variablesConection'

export async function getCotizacion(id) {
    return new Promise((resolve) => {
      GetApp(urlSAPMY+"?$select=C1RRZEUJZR0H0WN9UVYM41WUWDA,T1LVE57LA8BJFRU3XG44FM94SVF,TDOC_SAG_QLC_S,CDPT_END_DATE,KCITV_REQU_QTY_BU,KCITV_TAX_AMT_RC,TPERM_RES_UUID,C1K75IUHTU4VEQI131YHETB2135,C1OQMP35TRQZHJKK68WTWB3JO8I,CIPY_EMPLRSP_PTY,CDOC_CREATED_DT,CDOC_UUID,TDOC_CREATED_BY,KCITV_NET_AMT_RC,TIPY_BUYER_PTY,TIPY_EMPLRSP_PTY,TITM_UUID,CIPR_PRODUCT,Ts1ANs6D06A4DA147CF24,CITM_ID&$format=json&$top=99999&$filter=CDOC_UUID eq '"+id+"'").then((value) => {
        const vector = [];
        JSON.parse(value).d.results.forEach(element => {
          vector.push({
            comentario: element.C1RRZEUJZR0H0WN9UVYM41WUWDA,
            zona:element.T1LVE57LA8BJFRU3XG44FM94SVF,
            estado: element.TDOC_SAG_QLC_S,
            Fecha: element.CDOC_CREATED_DT,
            fechavalides: element.CDPT_END_DATE,
            Producto: element.CIPR_PRODUCT,
            Linea : element.CITM_ID,
            ProductoDescripcion: element.TITM_UUID,
            Valor: element.KCITV_NET_AMT_RC,
            Cotizacion :  element.CDOC_UUID,
            Asesor : element.TIPY_EMPLRSP_PTY,
            AsesorID : element.CIPY_EMPLRSP_PTY,
            Cliente: element.TIPY_BUYER_PTY,
            Telefono: element.C1OQMP35TRQZHJKK68WTWB3JO8I,
            Correo : element.C1K75IUHTU4VEQI131YHETB2135,
            Residencia : element.TPERM_RES_UUID,
            Impuesto : element.KCITV_TAX_AMT_RC,
            Cantidad :  element.KCITV_REQU_QTY_BU,
            iva :  ((element.KCITV_TAX_AMT_RC*100) / element.KCITV_NET_AMT_RC)*0.01

          })
        })
        resolve(vector);
      });
    });
  }


  
  export async function getCotizacionDtl(id) {
    return new Promise((resolve) => {
      GetApp("https://my360131.sapbydesign.com/sap/byd/odata/ana_businessanalytics_analytics.svc/RPZ30E64C5F8A319CDEF259ACQueryResults?$format=json&$filter=CDOC_UUID eq '"+id+"'&$select=CDOC_UUID,CSALES_PRICE_LIST_UUID,CITM_UUID,KCRATE_DECIMAL_VALUE,CEXCHANGE_RATE_UNIT_CURRENCY").then((value) => {
        const vector = [];
        JSON.parse(value).d.results.forEach(element => {
          if (element.CSALES_PRICE_LIST_UUID !== null) {
            vector.push({
              cotizacion: element.CDOC_UUID,
              listaprecio: element.CSALES_PRICE_LIST_UUID,
              valor: element.KCRATE_DECIMAL_VALUE,
              linea: element.CITM_UUID,
              moneda: element.CEXCHANGE_RATE_UNIT_CURRENCY
            });
          }
        });
        resolve(vector);
      });
    });
  }


  export async function getCotizacionDtlDescuento(id) {
    return new Promise((resolve) => {
      GetApp("https://my360131.sapbydesign.com/sap/byd/odata/ana_businessanalytics_analytics.svc/RPZ30E64C5F8A319CDEF259ACQueryResults?$format=json&$filter=CDOC_UUID eq '"+id+"' and CCATEGORY_CODE eq '2'&$select=CDOC_UUID,CITM_UUID,KCRATE_DECIMAL_VALUE,TCATEGORY_CODE,CCATEGORY_CODE").then((value) => {
        const vector = [];
        JSON.parse(value).d.results.forEach(element => {
          if (element.CSALES_PRICE_LIST_UUID !== null) {
            vector.push({
              linea: element.CITM_UUID,
              descuento: element.KCRATE_DECIMAL_VALUE
            });
          }
        });
        resolve(vector);
      });
    });
  }
  
  

  export async function getCotizacionContacto(id) {
    return new Promise((resolve) => {
      GetApp("https://my360131.sapbydesign.com/sap/byd/odata/ana_businessanalytics_analytics.svc/RPZF9740C0C39B361C530134DQueryResults?$select=TIPY_BUYER_CNT_PTY&$format=json&$top=99999&$filter=CDOC_UUID eq '"+id+"'").then((value) => {
        const vector = [];
        JSON.parse(value).d.results.forEach(element => {
          if (element.CSALES_PRICE_LIST_UUID !== null) {
            vector.push({
              contacto: element.TIPY_BUYER_CNT_PTY
            });
          }
        });
        resolve(vector);
      });
    });
  }
  