import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InformacionVideo from './informacionVideo';
import Pestanas from './pestanas'
import { Container } from '@mui/material';

export default function InformacionDtl({ data }) {

    return (
        <Container sx={{ maxWidth: 1200, position: "relative" }} style={{width:"100%"}}>
                <Typography variant="h6" color="text.primary" style={{ textAlign: "justify" }}>
                    Descripción de los productos
                </Typography>
                {data.map((row) => (
                                    <Pestanas linea={row.Linea} producto={row.Producto} ProductoDescripcion={row.ProductoDescripcion} Valor={row.Valor} Moneda={row.Moneda} Impuesto={row.Impuesto}
                                               Cantidad={row.Cantidad} dtl={row.dtl} iva={row.iva} descuento={row.descuento}
                                    ></Pestanas>
                            ))}
        </Container>
    );
}
