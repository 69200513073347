import React, { useState } from 'react';
import /* In the given code, `footerterminos` is imported as an image resource. However, it is not used
anywhere in the code. */
/* In the given code, `footerterminos` is imported as an image resource. However, it is not used
anywhere in the code. */
footerterminos from '../recursos/footerterminos.jpg';
import '../cssComponentes/Terminos.css';

export default function Inicio({ data }) {
  return (
    <div style={{ position: 'relative', width: '100%', margin:"20px 0px 0px 0px" }}>
      <img src={footerterminos} alt={"terminos"} style={{ width: '100%', height: ' ' }} />
    </div>
  );
}

