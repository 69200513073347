import React, { useState } from 'react';
import total from '../recursos/total.jpg';
import '../cssComponentes/Terminos.css';

export default function Totalimg({ data }) {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <img src={total} alt={"terminos"} style={{ width: '100%', height: ' ' }} />
    </div>
  );



}

