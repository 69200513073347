import React, { useState, useEffect } from 'react';
import Cabeza from './cabeza'
import '../cssComponentes/Inicio.css';
import { Card, Container, Grid } from '@mui/material';
import InformacionDtl from './informacionDtl';
import Total from './total';
import Totalimg from './totalimg';
import { getCotizacion, getCotizacionDtl, getCotizacionDtlDescuento, getCotizacionContacto } from '../consumos/sapCotizacion'
import SubCabeza from './subCabeza';
import Terminos from './terminos';
import Terminosfooter from './terminosfooter';
import { CardMedia, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import recarga from '../recursos/Recarga.jpg';
import recarga1 from '../recursos/RecargaAceptarc.jpg';
import recarga2 from '../recursos/RecargaRechazarc.jpg';
import logo from '../recursos/LOGOS SIN SLOGANc.png';
import { sendCorreo } from '../consumos/correo'

export default function Inicio() {

    const [visible, setVisible] = useState(false)
    const [aceptar, setAceptar] = useState(false)
    const [rechazar, setRechazar] = useState(false)
    const [cerrada, setCerrada] = useState(false)
    const [data, setData] = useState([
        {
            comentario:"",
            zona:"",
            estado: "",
            Fecha: "",
            fechavalides: "",
            Producto: "",
            Linea: "",
            ProductoDescripcion: "",
            Valor: "",
            Cotizacion: "",
            Asesor: "",
            Cliente: "",
            Telefono: "",
            Correo: "",
            Residencia: "",
            Moneda: "",
            Impuesto: "",
            Cantidad: "",
            dtl: {}
        }
    ]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const queryString = window.location.search;
                const params = new URLSearchParams(queryString);
                const cdocCreatedDt = params.get('$cotizacion');

                const [datos, datosv2, datosv3, datosv4] = await Promise.all([
                    getCotizacion(cdocCreatedDt),
                    getCotizacionDtl(cdocCreatedDt),
                    getCotizacionDtlDescuento(cdocCreatedDt),
                    getCotizacionContacto(cdocCreatedDt),
                ]);

                datos.forEach((elemento1) => {
                    const elemento2 = datosv2.find((e) => e.linea === elemento1.Linea);
                    const elemento3 = datosv3.find((e) => e.linea === elemento1.Linea);
                    elemento1.contacto = datosv4[0].contacto
                    if (elemento2) {
                        elemento1.dtl = elemento2;
                    } else {
                        elemento1.dtl = { valor: 1, moneda: "" };
                    }
                    if (elemento3) {
                        elemento1.descuento = elemento3;
                    } else {
                        elemento1.descuento = { linea: elemento1.linea, descuento: 0 };
                    }
                });

                if (datos[0].estado === "Completado") {
                    setCerrada(true);
                    setData(datos);
                } else {
                    setData(datos);
                    setVisible(true);
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }

        };
        fetchData();
    }, []);

    useEffect(() => {
        if (cerrada) {
            const body = {
                "Body": `<!DOCTYPE html> <html> <head> <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                    <title>Oferta De Venta - Plantilla correo electrónico</title>
                    <style>
                        body {
                            font-family: 'Times New Roman', serif;
                            font-size: 16px;
                            line-height: 1.6;
                            margin: 20px;
                        }
                        .card-container {
                            border: 1px solid #ccc;
                            border-radius: 8px;
                            padding: 20px;
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                            max-width: 600px;
                            /* Ajusta el ancho máximo según tus necesidades */
                            margin: auto;
                        }
                        p {
                            margin-bottom: 10px;
                        }
                        a {
                            text-decoration: none;
                            color: #007BFF;
                        }
                        span {
                            display: block;
                            margin-bottom: 5px;
                        }
                    </style>
                </head>
                <body>
                    <div class="card-container">
                        <p>Apreciado Asesor,</p>
                        <p>Es importante para nosotros informarle que la cotización ${data[0].Cotizacion} esta cerrada y el cliente intento abrirla de
                            nuevo</p>
                        <br>
                        <p>Atentamente,</p>
                    </div>
                </body>
                </html>`,
                "Correo":  data[0].Correo,
                "Asunto": "La cotización " + data[0].Cotizacion + " se consulto y esta cerrada"
            }
            sendCorreo(body)
        }
    }, [cerrada]);


    return (
        <div style={{ backgroundColor: "white" }}>
            {visible && aceptar && !cerrada ? <Card id="containerTamaño">
                <div className="box"> <Cabeza data={data}></Cabeza> </div>
                <div className="box"> <SubCabeza data={data}></SubCabeza> </div>
                <div className="box" ><InformacionDtl data={data}></InformacionDtl></div>
                <div className="box"> <Totalimg data={data}></Totalimg> </div>
                <div className="box"> <Total data={data}></Total> </div>
                <div className="box"> <Terminos data={data}></Terminos> </div>
                <div className="box"> <Terminosfooter data={data}></Terminosfooter> </div>
            </Card> : rechazar ?
                <div style={{ height: '100vh', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Card id="containerTamañov2" style={{ borderTop: '6px solid red', width: '60%', textAlign: 'center', padding: '20px' }}>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Grid item>
                                <img src={logo} alt={""} style={{ width: '50%', height: 'auto' }} /><br />
                                <Typography
                                    variant="h9"
                                    color="text.primary">
                                    Usted ha rechazado nuestra política para el tratamiento de datos personales, por lo tanto no podemos continuar con la propuesta comercial.
                                    Si cambia de opinión, puede acceder a ella nuevamente en el link que le fue suministrado a su correo electrónico.
                                    En caso de tener dudas, también puede contactar a su asesor comercial asignado o al celular<a
                                        href={`https://api.whatsapp.com/send?phone=+573102244698&text=Hola`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    > <b> 310 224 4698 </b> </a> .
                                </Typography><br /><br />
                            </Grid>
                        </Grid>
                    </Card>
                </div>
                : cerrada ?
                    <div style={{ height: '100vh', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card id="containerTamañov2" style={{ borderTop: '6px solid red', width: '60%', textAlign: 'center', padding: '20px' }}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <img src={logo} alt={""} style={{ width: '25%', height: 'auto' }} /><br />
                                    <Typography
                                        variant="h9"
                                        color="text.primary">
                                            Señor usuario, su cotización ha caducado. Por favor ponerse en contacto con su asesor comercial asignado o al celular<a
                                            href={`https://api.whatsapp.com/send?phone=+573102244698&text=Hola`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        > <b> 310 224 4698 </b> </a> .
                                    </Typography><br /><br />
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
                    :
                    <div style={{ height: '100vh', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card id="containerTamañov2" style={{ borderTop: '6px solid red', width: '60%', textAlign: 'center', padding: '20px' }}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                {/* Contenido de tu Card */}
                                <Grid item>
                                    <img src={logo} alt={""} style={{ width: '50%', height: 'auto' }} /><br />
                                    <Typography
                                        variant="h9"
                                        color="text.primary">
                                        Usted recibe la siguiente oferta comercial por solicitud propia y de esta forma acepta nuestras politicas de tratamiento de datos personales publicada en el siguiente <a
                                            href={`https://www.citalsa.com/pages/politica-para-el-tratamiento-de-datos-personales`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        > <b>link, </b> </a>
                                        de lo contrario es importante que reporte inmediatamente el incidente al siguiente correo
                                        electrónico: servicioalcliente@talsa.com.co.
                                    </Typography><br /><br />
                                </Grid>
                                <Grid item container spacing={2} justify="center">
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Button onClick={() => {
                                                        setAceptar(true)
                                                    }}>
                                                        <img src={recarga1} alt={""} style={{ width: '100%', height: 'auto' }} />
                                                    </Button>
                                                    {aceptar && !visible ? <Typography id="cargandoidtxt">Cargando...</Typography> : ""}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button onClick={() => { setRechazar(true) }}>
                                                        <img src={recarga2} alt={""} style={{ width: '100%', height: 'auto' }} />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
            }
        </div>
    );
}
