import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import { Container, Typography } from '@mui/material';
import { Grid, Paper } from '@material-ui/core';




const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 1000,
    marginLeft: '0px',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(1),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(1),
    },
  },
  media: {
    height: "199px",
    width: "70%",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('md')]: {
      width: '5%',
      height: "60px",
      marginLeft: spacing(5),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
}));

const Carta = ({ linea, producto, ProductoDescripcion, valueShopi, Valor, Moneda, Impuesto, Cantidad, dtl, iva, descuento, img2 }) => {
  const styles = useStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  const shadowStyles = useOverShadowStyles();


  
  

  return (

    <Card>

      {valueShopi &&
        valueShopi.data &&
        valueShopi.data.products &&
        valueShopi.data.products.edges &&
        valueShopi.data.products.edges.length > 0 &&
        valueShopi.data.products.edges[0].node.media.edges &&
        valueShopi.data.products.edges[0].node.media.edges.length > 0 &&
        valueShopi.data.products.edges[0].node.media.edges[0].node.image.originalSrc ? (

        <Container>
          <Card className={cx(styles.root, shadowStyles.root)}>
            <CardMedia
              style={{ padding: "10%" }}
              className={styles.media}
              id="Carta"
              image={img2=="img"?valueShopi.data.products.edges[0].node.media.edges[0].node.image.originalSrc :img2}
              />

            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
              <Grid item xs={12} style={{ border: '1px solid #000', padding: '10px', borderRadius: "8px", margin: "15px" }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid key={Math.random()} item>
                    <b>Producto:</b><br />
                    {ProductoDescripcion} <br />
                    SKU: {producto}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Card className={cx(styles.root, shadowStyles.root)}>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
              <Grid item xs={12} style={{ border: '1px solid #000', padding: '10px', borderRadius: "8px", margin: "15px" }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid key={Math.random()} item >
                    <b>Linea:</b><br />
                    {linea}
                  </Grid>
                  <Grid key={Math.random()} item >
                    <b>Precio unitario:</b><br />
                    {Number(dtl.valor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}{dtl.moneda}
                  </Grid>
                  <Grid key={Math.random()} item >
                    <b>Cantidad:</b><br />
                    {Number(Cantidad).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Grid>
                  <Grid key={Math.random()} item >
                    <b>Precio bruto:</b><br />
                    {Number(((dtl.valor * Cantidad))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Grid>
                  {descuento?.descuento !== 0 && (
                    <Grid key={Math.random()} item>
                      <b>Descuento(%):</b><br />
                      {
                        !isNaN(Number(descuento?.descuento))
                          ? Number(Number(descuento?.descuento) * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                          : 0
                      }
                    </Grid>
                  )}
                  <Grid key={Math.random()} item >
                    <b>Impuesto:</b><br />
                    {Number((dtl.valor * iva) * Number(Cantidad)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Grid>
                  <Grid key={Math.random()} item >
                    <b>Precio neto:</b><br />
                    {(((Number((Number(descuento?.descuento) * -1) / 100) - 1) * -1) * Number(Number(dtl.valor * Cantidad) + dtl.valor * iva * Number(Cantidad))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}{dtl.moneda}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Container>
      ) : (

        <Card className={cx(styles.root, shadowStyles.root)} >


          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12} style={{ border: '1px solid #000', padding: '10px', borderRadius: "8px", margin: "15px" }}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid key={Math.random()} item >
                  <b>Linea:</b><br />
                  {linea}
                </Grid>
                <Grid key={Math.random()} item>
                  <b>Producto:</b><br />
                  {ProductoDescripcion} <br />
                  SKU: {producto}
                </Grid><Grid key={Math.random()} item >
                  <b>Precio unitario:</b><br />
                  {Number(dtl.valor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}{dtl.moneda}
                </Grid>
                <Grid key={Math.random()} item >
                  <b>Cantidad:</b><br />
                  {Number(Cantidad).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </Grid>
                <Grid key={Math.random()} item >
                  <b>Precio bruto:</b><br />
                  {Number(((dtl.valor * Cantidad))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </Grid>
                {descuento?.descuento !== 0 && (
                    <Grid key={Math.random()} item>
                      <b>Descuento(%):</b><br />
                      {
                        !isNaN(Number(descuento?.descuento))
                          ? Number(Number(descuento?.descuento) * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                          : 0
                      }
                    </Grid>
                  )}
                <Grid key={Math.random()} item >
                  <b>Impuesto:</b><br />
                  {Number((dtl.valor * iva) * Number(Cantidad)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </Grid>
                <Grid key={Math.random()} item >
                  <b>Precio neto:</b><br />
                  {(((Number((Number(descuento?.descuento) * -1) / 100) - 1) * -1) * Number(Number(dtl.valor * Cantidad) + dtl.valor * iva * Number(Cantidad))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}{dtl.moneda}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )
      }

      {valueShopi && valueShopi.data && valueShopi.data.products && valueShopi.data.products.edges &&
        valueShopi.data.products.edges.length > 0 &&
        valueShopi.data.products.edges[0].node.descriptionHtml ? <Typography variant="body" component="div" style={{ textAlign: "justify", padding: "10px" }} dangerouslySetInnerHTML={{
          __html:
            valueShopi && valueShopi.data && valueShopi.data.products && valueShopi.data.products.edges &&
            valueShopi.data.products.edges.length > 0 &&
            valueShopi.data.products.edges[0].node.descriptionHtml
        }} /> : ""}

    </Card>

  );
};

export default Carta;
