import React, { useState } from 'react';
import /* In the given code, `footerterminos` is imported as an image resource. However, it is not used
anywhere in the code. */
/* In the given code, `footerterminos` is imported as an image resource. However, it is not used
anywhere in the code. */
footerterminos from '../recursos/footerterminos.jpg';
import terminos from '../recursos/terminosv2.jpg';
import terminoswpp from '../recursos/terminoswpp.jpg';
import terminosllamada from '../recursos/terminosllamada.jpg';
import terminospagos from '../recursos/terminospagos.jpg';
import wpptr from '../recursos/wpptr.png';
import llamadatr from '../recursos/llamadatr.png';
import { Button, Container, Grid, Typography } from '@mui/material';
import '../cssComponentes/Terminos.css';
import { sendCorreo } from '../consumos/correo'

export default function Inicio({ data }) {
  const body = {
    "Body": `<!DOCTYPE html> <html> <head> <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Oferta De Venta - Plantilla correo electrónico</title>
        <style>
            body {
                font-family: 'Times New Roman', serif;
                font-size: 16px;
                line-height: 1.6;
                margin: 20px;
            }
            .card-container {
                border: 1px solid #ccc;
                border-radius: 8px;
                padding: 20px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                max-width: 600px;
                /* Ajusta el ancho máximo según tus necesidades */
                margin: auto;
            }
            p {
                margin-bottom: 10px;
            }
            a {
                text-decoration: none;
                color: #007BFF;
            }
            span {
                display: block;
                margin-bottom: 5px;
            }
        </style>
    </head>
    <body>
        <div class="card-container">
            <p>Apreciado Asesor,</p>
            <p>Es importante para nosotros informarle que la cotización ${data[0].Cotizacion} fue aprobada</p>
            <br>
            <p>Atentamente,</p>
        </div>
    </body>
    </html>`,
    "Correo": data[0].Correo,
    "Asunto": "La cotización " + data[0].Cotizacion + " se consulto y fue aprobada"
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <img src={terminos} alt={"terminos"} style={{ width: '100%', height: ' ' }} />
      <Container >
        <Grid container spacing={2} style={{ position: 'relative', textAlign: "justify" }}>
          <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
            <Container >
              <Typography style={{ fontWeight: "bold", fontSize: "1 em", textAlign: "start" }}> —{data[0].Cliente}, Si deseas aprobar la propuesta comercial o tienes dudas adicionales respecto a esta cotización, puedes realizarlo a través de: </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} md={4} style={{ textAlign: "start" }}>
            <Container >
              <Button onClick={() => {
                const telefonoSinEspacios = data[0].Telefono.replace(/\s/g, '');
                const mensaje = `Hola, recibí la propuesta comercial ${data[0].Cotizacion} y quisiera continuar con la negociación`;
                const url = `https://api.whatsapp.com/send?phone=${telefonoSinEspacios}&text=${encodeURIComponent(mensaje)}`;
                // Abre la ventana de WhatsApp al hacer clic en el botón

                sendCorreo(body)
                window.open(url, '_blank', 'noopener,noreferrer');
              }}>
                <img
                  src={wpptr}
                  alt="Descripción de la imagen"
                  style={{ width: '60%', height: 'auto' }}
                />
              </Button>

            </Container>
            </Grid>
          <Grid item xs={12} md={12} style={{ textAlign: "start" }}>
            <Container >
              <Typography style={{  fontSize: "1 em", textAlign: "start", margin:"10px" }}> 
                O también puedes llamar al siguiente número: 
                <a href="#" onClick={() => {
                  const telefonoSinEspacios = data[0].Telefono.replace(/\s/g, '');
                  const telefonoUri = `tel:${telefonoSinEspacios}`;
                  sendCorreo(body)
                  window.location.href = telefonoUri;
                  
                }}>
                   {data[0].Telefono}
                </a>
              </Typography>

              {/*<Button onClick={() => {
                const telefonoSinEspacios = data[0].Telefono.replace(/\s/g, '');
                const telefonoUri = `tel:${telefonoSinEspacios}`;
                sendCorreo(body)
                window.location.href = telefonoUri;
              }}>

                <img
                  src={terminosllamada}
                  alt="Descripción de la imagen"
                  style={{ width: '60%', height: 'auto' }}
                />
            </Button>*/}
            </Container>
          </Grid>


          <Grid item xs={12} md={12} style={{ textAlign: "justify", paddingTop:"40px"  }}>
            <Container >
              <Typography style={{ fontWeight: "bold", fontSize: "1 em", textAlign: "start" }}> — Si desea realizar el pago total o un abono a esta propuesta, puede darle click al siguiente botón: </Typography>
            </Container>
          </Grid>

          <Grid item xs={12} md={4} style={{ textAlign: "start", backgroundColor: "" }}>
            <Button onClick={() => {
              window.open("https://api.openpay.co/v1/mjw4vtxuzw49ecdgl5uu/open-checkout", '_blank')
            }}>
              <img
                src={terminospagos}
                alt="Descripción de la imagen"
                style={{ width: '55%', height: 'auto' }}
              />

            </Button>
          </Grid>
          <Grid item xs={12} md={6} style={{}}>

          </Grid>

          {/*Termino footer*/}

      
        </Grid>
      </Container>
    </div>
  );



}

