import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Mp({mp }) {


    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Fecuencia</TableCell>
              <TableCell align="left">Sku</TableCell>
              <TableCell align="right">descripción</TableCell>
              <TableCell align="right">cantidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mp.map((row) => (
              <TableRow
                key={row.SKUSAP}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.FRECUENCIA}
                </TableCell>
                <TableCell align="right">{row.SKUSAP}</TableCell>
                <TableCell align="right">{row.DESCRIPCIONSKU}</TableCell>
                <TableCell align="right">{row.CANTIDAD}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}
