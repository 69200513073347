import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { CardContent, Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Total({ data }) {

  const [visible, setVisible] = useState(false)

  const totals = {
    USD: 0,
    EUR: 0,
    COP: 0,
    USD1: 0,
    EUR1: 0,
    COP1: 0,
    USD2: 0,
    EUR2: 0,
    COP2: 0,
  };

  data.forEach((item) => {
    const currency = item.dtl.moneda;
    const valorBruto = parseFloat(item.dtl.valor * item.Cantidad);
    const valorNeto = parseFloat(Number((((item.descuento?.descuento * -1) / 100) - 1) * -1) * Number(Number(item.dtl.valor * item.Cantidad) + item.dtl.valor * item.iva * Number(item.Cantidad)));
    var valorDescuento = parseFloat(1 - Number((((item.descuento?.descuento * -1) / 100) - 1) * -1) * Number(Number(item.dtl.valor * item.Cantidad)) - Number(Number(item.dtl.valor * item.Cantidad)) * -1);
    totals[currency] += valorBruto;
    totals[currency + "1"] += valorNeto;
    totals[currency + "2"] += valorDescuento == 1 ? 0 : valorDescuento - 1;
    if (valorDescuento !== 1 && !visible) {
      setVisible(true);
      console.log("1")
    }
  });


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0.5} translate="no" style={{ padding: "20px" }}>

        <Grid item xs={12}>
          <Item>
            <b>VALORES TOTALES MONEDA DE ORIGEN</b><br />
          </Item>
        </Grid>
        <Grid item xs={1} translate="no">
          <Item >
            <b>Moneda:</b><br />
          </Item>
          <Item>
            <b>USD:</b><br />
          </Item>
          <Item>
            <b>EUR:</b><br />
          </Item>
          <Item>
            <b>COP:</b><br />
          </Item>
        </Grid>

        <Grid item xs={visible ? 4 : 5}>
          <Item>
            <b>Precio bruto:</b><br />
          </Item>
          <Item>
            <b>{totals.USD.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
          </Item>
          <Item>
            <b> {totals.EUR.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
          </Item>
          <Item>
            <b> {totals.COP.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
          </Item>
        </Grid>
        {visible ? <Grid item xs={3}>
          <Item>
            <b>Descuento:</b><br />
          </Item>
          <Item>
            {totals.USD2.toFixed(2) == 0 ? <b>-</b> : <b>{totals.USD2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>}
          </Item>
          <Item>
            {totals.EUR2.toFixed(2) == 0 ? <b>-</b> : <b>{totals.EUR2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>}
          </Item>
          <Item>
            {totals.COP2.toFixed(2) == 0 ? <b>-</b> : <b>{totals.COP2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>}
          </Item>
        </Grid> : ""}

        <Grid item xs={visible ? 4 : 6}>
          <Item>
            <b>Precio con iva:</b><br />
          </Item>
          <Item>
            <b>{totals.USD1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
          </Item>
          <Item>
            <b> {totals.EUR1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
          </Item>
          <Item>
            <b> {totals.COP1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
          </Item>
        </Grid>

        {data.length > 0 && data[0].comentario !== "" && (
          <>
            <Grid item xs={12}>
              <Item>
                <b>Nota:</b><br />
              </Item>
            </Grid>
            <Grid item xs={12} md={12}> 
            <CardContent>
              <Typography variant='h6' style={{ fontSize: "0.8em", margin: "20px", overflowWrap: "break-word" }}>
                {data[0].comentario}
              </Typography>
            </CardContent>
            </Grid>
          </>
        )}


      </Grid>
    </Box>
  );
}