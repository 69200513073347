export async function getProductosImg(id) {

    return new Promise((resolve) => {
        var datos = [
            { producto: '21310030', url: 'https://www.citalsa.com/cdn/shop/files/Capturadepantalla2023-12-29100728.png' },
            { producto: '21310033', url: 'https://www.citalsa.com/cdn/shop/files/Capturadepantalla2023-12-29100620_142fc804-35de-4739-8ab3-083c9b727354.png' },
            { producto: '21310032', url: 'https://www.citalsa.com/cdn/shop/files/Capturadepantalla2023-12-29100751.png' },
            { producto: '21310031', url: 'https://www.citalsa.com/cdn/shop/files/Capturadepantalla2023-12-29100702.png' },
            { producto: '21790006', url: 'https://www.citalsa.com/cdn/shop/products/14220023_1_ee6ac1f1-280e-4a88-8bbf-a4556fe8dfa2.jpg' },
            { producto: '21790003', url: 'https://www.citalsa.com/cdn/shop/products/14220020_1_8428b210-3730-4eb0-b4fe-8e30ef1bab3d.jpg' },
            { producto: '21790008', url: 'https://www.citalsa.com/cdn/shop/products/14220050_1_5e06fb1b-0fc9-4932-9ece-7146f14c07df.jpg' },
            { producto: '21790004', url: 'https://www.citalsa.com/cdn/shop/products/14220021_1_b4654fd5-60fd-4047-9cd3-5ccda620b97a.jpg' },
            { producto: '21790005', url: 'https://www.citalsa.com/cdn/shop/products/14220022_1_edf6c5a4-0e2b-41ef-9317-8511d80972f8.jpg' },
            { producto: '21790007', url: 'https://www.citalsa.com/cdn/shop/products/14220024_1_6de8c73e-d578-4c1f-b7c3-8c26ecef0bf1.jpg' },
            { producto: '29010076', url: 'https://www.citalsa.com/cdn/shop/products/NTC171.jpg' },
            { producto: '29010077', url: 'https://www.citalsa.com/cdn/shop/products/NTH509.jpg' },
            { producto: '29010075', url: 'https://www.citalsa.com/cdn/shop/products/NTD005.jpg' },
            { producto: '29010068', url: 'https://www.citalsa.com/cdn/shop/products/NTC382_82e1651b-5c45-400e-889e-94a2becf8851.jpg' },
            { producto: '29010063', url: 'https://www.citalsa.com/cdn/shop/products/NTC187.jpg' },
            { producto: '29010071', url: 'https://www.citalsa.com/cdn/shop/products/NTJ399.jpg' },
            { producto: '29010060', url: 'https://www.citalsa.com/cdn/shop/products/NTC147.jpg' },
            { producto: '29010086', url: 'https://www.citalsa.com/cdn/shop/products/NTJ399.jpg' },
            { producto: '29010062', url: 'https://www.citalsa.com/cdn/shop/products/NTC171.jpg' },
            { producto: '29010059', url: 'https://www.citalsa.com/cdn/shop/products/NTA942.jpg' },
            { producto: '29010069', url: 'https://www.citalsa.com/cdn/shop/products/NTC390.jpg' },
            { producto: '29010100', url: 'https://www.citalsa.com/cdn/shop/products/NTO497.jpg' },
            { producto: '29010074', url: 'https://www.citalsa.com/cdn/shop/products/NTC791.jpg' },
            { producto: '29010061', url: 'https://www.citalsa.com/cdn/shop/products/NTC149.jpg' },
            { producto: '29010067', url: 'https://www.citalsa.com/cdn/shop/products/NTC381.jpg' },
            { producto: '29010070', url: 'https://www.citalsa.com/cdn/shop/products/NTC406.jpg' },
            { producto: '29010097', url: 'https://www.citalsa.com/cdn/shop/products/NTK823.jpg' },
            { producto: '29010058', url: 'https://www.citalsa.com/cdn/shop/products/NTA530.jpg' },
            { producto: '29010137', url: 'https://www.citalsa.com/cdn/shop/products/NTK693.jpg' },
            { producto: '29010066', url: 'https://www.citalsa.com/cdn/shop/products/NTC359.jpg' },
            { producto: '29010095', url: 'https://www.citalsa.com/cdn/shop/products/NTJ637_1.jpg' },
            { producto: '11180006', url: 'https://www.citalsa.com/cdn/shop/products/IMAGENCOMPARATIVA.png' },
            { producto: '11180008', url: 'https://www.citalsa.com/cdn/shop/products/IMAGENCOMPARATIVA.png' },
            { producto: '11180021', url: 'https://www.citalsa.com/cdn/shop/products/IMAGENCOMPARATIVA.png' },
            { producto: '21500007', url: 'https://www.citalsa.com/cdn/shop/files/21500007.png?v=1707843496' },
            { producto: '21500003', url: 'https://www.citalsa.com/cdn/shop/products/20620015_1_ff97577f-0fdb-4899-89a5-aad7aec5d568.jpg' },
            { producto: '21500004', url: 'https://www.citalsa.com/cdn/shop/products/20620017_1_f125e8a1-f2d6-4e29-bf73-d5b0d25fef5c.jpg' },
            { producto: '21500006', url: 'https://www.citalsa.com/cdn/shop/products/20620016_1_4b039144-1141-4819-8bfc-4c6c9187c5aa.jpg' },
            { producto: '29010099', url: 'https://www.citalsa.com/cdn/shop/files/NTO083-removebg-preview.png' },
            { producto: '29010064', url: 'https://www.citalsa.com/cdn/shop/files/NTC311-removebg-preview.png' },
            { producto: '29010065', url: 'https://www.citalsa.com/cdn/shop/files/NTC251-removebg-preview.png' },
            { producto: '29000052', url: 'https://www.citalsa.com/cdn/shop/files/WhatsAppImage2024-02-02at09.39.51.jpg' },
            { producto: '29000050', url: 'https://www.citalsa.com/cdn/shop/files/WhatsAppImage2024-02-02at09.39.50_1.jpg' },
            { producto: '29000049', url: 'https://www.citalsa.com/cdn/shop/files/WhatsAppImage2024-02-02at09.39.51_1.jpg' },
            { producto: '29000054', url: 'https://www.citalsa.com/cdn/shop/files/WhatsAppImage2024-02-02at09.38.46_1.jpg' },
            { producto: '29000056', url: 'https://www.citalsa.com/cdn/shop/files/WhatsAppImage2024-02-02at09.39.50.jpg' },
            { producto: '29000127', url: 'https://www.citalsa.com/cdn/shop/files/WhatsAppImage2024-02-02at09.39.50_2.jpg' },
            { producto: '21360021', url: 'https://www.citalsa.com/cdn/shop/files/10720098_fea17cf3-8bd2-4b4c-b082-d6aca81d8888.jpg' },
            { producto: '21360029', url: 'https://www.citalsa.com/cdn/shop/files/10720087_1_56ae3db3-ba1f-4c1e-b672-73e0029901d1.jpg' },
            { producto: '21360027', url: 'https://www.citalsa.com/cdn/shop/files/10720043_1_5cf807df-d0f4-48f5-ac4b-f0d5b4c2f69d.jpg' },
            { producto: '21360012', url: 'https://www.citalsa.com/cdn/shop/files/10720037_0ae8132f-9ace-464b-86ba-cbe17a6ff855.jpg' },
            { producto: '21360035', url: 'https://www.citalsa.com/cdn/shop/files/10720043_1_5cf807df-d0f4-48f5-ac4b-f0d5b4c2f69d.jpg' },
            { producto: '21360022', url: 'https://www.citalsa.com/cdn/shop/files/10720043_1_5cf807df-d0f4-48f5-ac4b-f0d5b4c2f69d.jpg' },
            { producto: '21360017', url: 'https://www.citalsa.com/cdn/shop/files/10720043_1_5cf807df-d0f4-48f5-ac4b-f0d5b4c2f69d.jpg' },
            { producto: '21360014', url: 'https://www.citalsa.com/cdn/shop/files/10720048_1_d394790a-dc75-4e0a-b18b-e0ca63ddce61.jpg' },
            { producto: '21360016', url: 'https://www.citalsa.com/cdn/shop/files/10720061_1_530aadf5-e02a-4235-8b59-3c1e947a0a77.jpg' },
        ]
        var proveedor = false;
        var url = id
        datos.forEach(element => {
            if (element.producto == id) {
                proveedor = true;
                url = element.url
            }
        });
        if (proveedor) {
            resolve(url)
        } else {
            resolve("img")
        }
    })


}