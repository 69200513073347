export async function getClientes(id){
    console.log(id)
    return new Promise((resolve) => {
        var datos = [
            {nombre:'COMPAÑIA NACIONAL DE CHOCOLATES S.A.', asesor:{nombre:"Gabriel Jaime Perez Toro",correo:"gabriel.perez@talsa.com.co",telefono:"+57 3174329735"}},
            {nombre:'SERVICIOS NUTRESA S.A.S', asesor:{nombre:"Gabriel Jaime Perez Toro",correo:"gabriel.perez@talsa.com.co",telefono:"+57 3174329735"}},
            {nombre:'ALIMENTOS CARNICOS SAS', asesor:{nombre:"Gabriel Jaime Perez Toro",correo:"gabriel.perez@talsa.com.co",telefono:"+57 3174329735"}},
            {nombre:'INDUSTRIA DE ALIMENTOS ZENU S.A.S', asesor:{nombre:"Gabriel Jaime Perez Toro",correo:"gabriel.perez@talsa.com.co",telefono:"+57 3174329735"}},
            {nombre:'COMPAÑIA DE GALLETAS NOEL SA.S', asesor:{nombre:"Gabriel Jaime Perez Toro",correo:"gabriel.perez@talsa.com.co",telefono:"+57 3174329735"}},
        ]
        var asesor = false;
        var url = id
        datos.forEach(element => {
            if(element.nombre == id){
                asesor = true;
                url = element.asesor
            }
        });

        if(asesor){
            resolve(url)
        }else{
            resolve(false)
        }
    })
}