import React, { useState } from 'react';
import '../cssComponentes/Cabeza.css';
import cabeza from '../recursos/Cabezav2c.jpg';
import { Container, Typography } from '@mui/material';

export default function Inicio({ data }) {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <img src={cabeza} alt={"Cabeza"} style={{ width: '100%', height: ' ' }} />
      <Container maxWidth="md">
        <Container style={{ textAlign: 'center', paddingBottom: '100px' }}>
          <Typography
            variant="h4"
            id='cliente'
            color="text.primary">
            {data[0].Cliente}
          </Typography>
        </Container>
      </Container>
    </div>
  );
}
